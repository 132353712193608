export default function Success({ paymentType, handleAction, t }) {
  const free = (
    <>
      <h1 className='text-4xl font-extrabold lg:leading-[50px] text-white'>
        {t('success.free.title')}
      </h1>
      <p>{t('success.free.message')}</p>
    </>
  );

  const paid = (
    <>
      <h1 className='text-4xl font-extrabold lg:leading-[50px] text-white'>
        {t('success.paid.title')}
      </h1>
      <p className='text-white'> {t('success.paid.message')}</p>
    </>
  );

  const options = {
    pix: paid,
    credit_card: paid,
    free: free,
  };
  return (
    <>
      <div className='flex flex-col justify-center items-center text-center mt-6'>
        {options[paymentType]}
        <button
          className='w-2/12 shadow-xl py-3 px-6 text-sm font-semibold rounded-md  from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
          onClick={handleAction}
        >
          {t('success.home')}
        </button>
      </div>
    </>
  );
}
