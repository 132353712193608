import {
  isCVV,
  isCreditCardNumber,
  isEmpty,
  isEmail,
  isCPForCNPJ,
  isMobile,
} from '../helpers/validation';

export const useError = () => {
  const goToFieldWithError = (id) => {
    const element = document.getElementById(id);
    let offset = 150;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
    document.getElementById(id).focus();
  };

  const attendeeError = {
    name: (attendee, t) => !isEmpty(attendee.name),
    email: (attendee, t) => isEmail(attendee.email),
    mobile: (attendee, t) => isMobile(attendee.mobile),
    document: (attendee, t) => isCPForCNPJ(attendee.document),
    address: (attendee, t) => !isEmpty(attendee.address),
    birthdate: (attendee, t) => !isEmpty(attendee.birthdate),
    gender: (attendee, t) => !isEmpty(attendee.gender),
    occupation: (attendee, t) => !isEmpty(attendee.occupation),
    crp: (attendee, t) => {
      if (attendee.occupation === t('attendee.occupations.psicologo')) {
        return !isEmpty(attendee.crp);
      }
      return true;
    },
    crm: (attendee, t) => {
      if (
        attendee.occupation === t('attendee.occupations.psiquiatra') ||
        attendee.occupation === t('attendee.occupations.medicina')
      ) {
        return !isEmpty(attendee.crm);
      }
      return true;
    },
    institution: (attendee, t) => true,
    which: (attendee, t) => {
      if (attendee.occupation === t('attendee.occupations.outras_areas')) {
        return !isEmpty(attendee.which);
      }
      return true;
    },
    registrationid: (attendee, t) => {
      if (attendee.occupation === t('attendee.occupations.outras_areas')) {
        return !isEmpty(attendee.registrationid);
      }
      return true;
    },
    term_1: (attendee, t) => {
      return attendee.term_1 === true;
    },
    term_2: (attendee, t) => {
      return true;
    },
  };

  const cctokenError = {
    number: (cctoken) => isCreditCardNumber(cctoken.number),
    name: (cctoken) => !isEmpty(cctoken.name),
    country: (cctoken) => !isEmpty(cctoken.country),
    expireAt: (cctoken) => !isEmpty(cctoken.expireAt),
    code: (cctoken) => isCVV(cctoken.code),
  };

  const customerError = {
    name: (customer) => !isEmpty(customer.name),
    email: (customer) => isEmail(customer.email),
  };
  return {
    attendeeError,
    customerError,
    cctokenError,
    goToFieldWithError,
  };
};
