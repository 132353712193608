import axios from 'axios';

const emailValidation = async ({ eventId, email }) => {
  const config = {
    method: 'GET',
    url: `${
      process.env.REACT_APP_BASE_URL
    }/wainer/events/${eventId}/emails/${email.trim()}/valid`,
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

const documentValidation = async ({ eventId, document }) => {
  const config = {
    method: 'GET',
    url: encodeURI(
      `${
        process.env.REACT_APP_BASE_URL
      }/wainer/events/${eventId}/documents/${document.trim()}/valid`,
    ),
    headers: {
      'content-type': 'application/json',
    },
  };

  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    return error.response.data;
  }
};

export { emailValidation, documentValidation };
