const attendeeDefault = {
  name: '',
  email: '',
  document: '',
  mobile: '',
  address: '',
  birthdate: '',
  gender: '',
  occupation: '',
  crp: '',
  crm: '',
  institution: '',
  which: '',
  registrationid: '',
  term_1: false,
  term_2: true,
  tag: '',
};

const cctokenDefault = {
  number: '',
  name: '',
  country: '',
  expireAt: '',
  code: '',
  installments: 1,
};

const customerDefault = {
  name: '',
  email: '',
  document: '',
  address: '',
};

const transactionDefault = {
  promoCode: 'NOCODE',
  eventId: process.env.REACT_APP_EVENT_ID,
  eventCode: parseInt(process.env.REACT_APP_EVENT_ID_MICE),
  attendees: [],
  products: [],
  paymentType: 'pix',
  customer: null,
  token: '',
  installments: 1,
};

const transactionResponseDefault = { success: false, data: null };

export {
  attendeeDefault,
  cctokenDefault,
  customerDefault,
  transactionDefault,
  transactionResponseDefault,
};
