import React, { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

export const Alert = ({ isOpen, toggle, message }) => {
  return (
    <>
      {isOpen && (
        <SweetAlert
          title=''
          onConfirm={(e) => {}}
          customClass='my-alert'
          customButtons={
            <React.Fragment>
              <button
                className='w-25 flex items-center justify-center gap-3 mt-6 px-5 py-3 bg-gradient-to-r from-teal-900 to-teal-600 text-white font-bold rounded-lg transition-all duration-300 hover:from-gray-800 hover:to-teal-300'
                onClick={toggle}
              >
                OK
              </button>
            </React.Fragment>
          }
        >
          {message}
        </SweetAlert>
      )}
    </>
  );
};
