import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useError } from '../../hooks/useError';

import { Card, CardBody, Row, Col } from 'reactstrap';

import Header from '../../components/header';
import { Attendee } from '../../components/attendee';
import Cart from '../../components/cart';
import Steps from '../../components/steps';
import { Alert } from '../../components/modal';
import Event from '../../components/event';
import { Token } from '../../components/token';
import PromoCode from '../../components/promocode';
import { PixQrCode } from '../../components/pixqrcode';
import PaymentOption from '../../components/paymentOption';
import { Customer } from '../../components/customer';

import { TransactionContext } from '../../contexts/TransactionContext';

import {
  attendeeDefault,
  transactionDefault,
  customerDefault,
  cctokenDefault,
  transactionResponseDefault,
} from '../../helpers/models';

import { CREDITCARD, FREE, PIX, PIXEXPIRETIME } from '../../helpers/constants';

import { createPaymentToken } from '../../services/cctoken';
import { sendTransaction } from '../../services/transaction';
import { calculate } from '../../services/calculate';
import {
  emailValidation,
  documentValidation,
} from '../../services/emailvalidation';

import Success from '../../components/success';

export default function Payment() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const {
    cart,
    nextStep,
    prevStep,
    step,
    remainingTime,
    setRemainingTime,
    email,
    setEmail,
  } = useContext(TransactionContext);

  const { attendeeError, cctokenError, customerError, goToFieldWithError } =
    useError();

  const [amount, setAmount] = useState(null);

  const [attendees, setAttendees] = useState([]);

  const fillAttendee = (name, value, index) =>
    setAttendees((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], [name]: value };
      return newState;
    });

  const [transaction, setTransaction] = useState(transactionDefault);
  const fillTransaction = (name, value) => {
    setTransaction((prevState) => ({ ...prevState, [name]: value }));
  };

  const [customer, setCustomer] = useState(customerDefault);
  const fillCustomer = (name, value) => {
    setCustomer((prevState) => ({ ...prevState, [name]: value }));
  };

  const [cctoken, setCctoken] = useState(cctokenDefault);
  const fillToken = (name, value) => {
    setCctoken((prevState) => ({ ...prevState, [name]: value }));
  };

  const [htmlId, setHtmlId] = useState('');
  const [message, setMessage] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggleModal = () => {
    setIsOpenModal(false);
    setTimeout(() => {
      goToFieldWithError(htmlId);
    }, 50);
  };
  const openModal = () => setIsOpenModal(true);

  const [disabledBuy, setDisabledBuy] = useState(false);
  const doNothing = () => {
    setDisabledBuy(false);
    return;
  };

  const [paid, setPaid] = useState(false);

  const [transactionResponse, setTransactionResponse] = useState(
    transactionResponseDefault,
  );

  const [errorMessage, setErrorMessage] = useState('');

  const [promoCode, setPromoCode] = useState('');

  const [calc, setCalc] = useState(null);

  const validate = (name, fields, error, model, index = -1) => {
    let response = true;
    for (let field in fields) {
      let validated = error[field](model, t);
      if (validated === false) {
        const id =
          index > -1 ? `${name}-${index}-${field}` : `${name}-${field}`;
        const msg = `${name}.errors.${field}`;
        setHtmlId(id);
        setMessage(t(msg));
        openModal();
        response = false;
        break;
      }
    }
    return response;
  };

  const isValidEmail = async (email, index) => {
    if (email === undefined || email === null || email?.trim() === '') {
      return;
    }
    const data = {
      eventId: transaction.eventId,
      email: email,
    };
    const response = await emailValidation(data);

    if (!response.success) {
      setHtmlId(`attendee-${index}-email`);
      setMessage(t('tickets.errors.emailalreadyadded'));
      openModal();
      return;
    }
  };

  const isValidDocument = async (document, index) => {
    if (
      document === undefined ||
      document === null ||
      document?.trim() === ''
    ) {
      return;
    }
    const data = {
      eventId: transaction.eventId,
      document: document,
    };
    const response = await documentValidation(data);

    if (!response.success) {
      setHtmlId(`attendee-${index}-document`);
      setMessage(t('tickets.errors.docalreadyadded'));
      openModal();
      return;
    }
  };

  const handleNext = async (e) => {
    if (step <= attendees.length) {
      const index = step - 1;
      const attendee = attendees[index];
      const attendeeErrorFields = t('attendee.errors', {
        returnObjects: true,
      });
      const validated = validate(
        'attendee',
        attendeeErrorFields,
        attendeeError,
        attendee,
        index,
      );

      if (validated) {
        await isValidDocument(attendee.document, index);

        const data = {
          eventId: transaction.eventId,
          email: attendee.email,
        };

        const emails = attendees.filter(
          (attendee) => attendee.email === data.email,
        );
        const emailAlreadyAdded = emails.length > 1;
        const response = await emailValidation(data);

        if (emailAlreadyAdded || !response.success) {
          setHtmlId(`attendee-${index}-email`);
          setMessage(t('tickets.errors.emailalreadyadded'));
          openModal();
          return;
        } else {
          nextStep();
        }
      }
    }
  };

  const handlePrev = (e) => {
    if (step > 1) {
      prevStep();
    } else {
      const emailFilled = email !== undefined && email !== null && email !== '';
      if (emailFilled) {
        navigate('/carrinho');
      } else {
        navigate('/');
      }
    }
  };

  const handlePix = (e) => {
    setAmount(calc.pix);
    fillTransaction('paymentType', PIX);
  };
  const handleCreditCard = (e) => {
    setAmount(calc.credit_card[cctoken.installments]);
    fillTransaction('paymentType', CREDITCARD);
  };
  const handlePromoCode = async (e) => {
    e.preventDefault();
    if (promoCode === '') {
      return;
    }
    const data = {
      products: cart.map((product) => {
        return { id: product.id, count: product.count };
      }),
      code: promoCode,
    };
    const response = await calculate(data);
    if (response.success) {
      setCalc(response.data);
      if (response.data.pix > 0 && response.data.pix !== amount) {
        fillTransaction('promoCode', promoCode);

        if (transaction.paymentType === PIX) {
          setAmount(response.data.pix);
        }
        if (transaction.paymentType === CREDITCARD) {
          setAmount(response.data.credit_card[cctoken.installments]);
        }
        if (transaction.paymentType === FREE) {
          fillTransaction('paymentType', PIX);
        }
      }
      if (response.data.pix === 0) {
        fillTransaction('paymentType', FREE);
        fillTransaction('promoCode', promoCode);
        setAmount(response.data.pix);
      }
    }
  };
  const handleBuy = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setDisabledBuy(true);
    if (transaction.paymentType === CREDITCARD) {
      const ccTokenErrorFields = t('cctoken.errors', {
        returnObjects: true,
      });
      const validatedCctoken = validate(
        'cctoken',
        ccTokenErrorFields,
        cctokenError,
        cctoken,
      );
      if (!validatedCctoken) {
        return doNothing();
      }
      const tokenResponse = await createPaymentToken(
        transaction.paymentType,
        cctoken,
      );
      if (tokenResponse?.success) {
        transaction.token = tokenResponse.token;
      } else {
        setErrorMessage(tokenResponse.message);
        return doNothing();
      }
    }

    const customerErrorFields = t('customer.errors', {
      returnObjects: true,
    });

    const validatedCustomer = validate(
      'customer',
      customerErrorFields,
      customerError,
      customer,
    );

    if (!validatedCustomer) {
      return doNothing();
    }

    transaction.customer = customer;
    transaction.attendees = attendees;
    transaction.products = cart.map((product) => product.id);
    transaction.installments =
      transaction.paymentType === CREDITCARD
        ? parseInt(cctoken.installments)
        : 1;

    const response = await sendTransaction(transaction);
    setTransactionResponse(response);

    if (response.success) {
      if (transaction.paymentType === PIX) {
        setRemainingTime(PIXEXPIRETIME);
      }
      if (
        transaction.paymentType === CREDITCARD ||
        transaction.paymentType === FREE
      ) {
        setPaid(true);
      }
    } else {
      try {
        const errorMessages = {
          ALREADY_BOUGHT: t('tickets.errors.alreadybought'),
          PRODUCT_NOT_AVAILABLE: t('tickets.errors.productnotavailable'),
          PROMO_NOT_AVAILABLE: t('tickets.errors.promonotavailable'),
        };
        const newErrorMessage =
          errorMessages[response?.data?.message] || t('tickets.errors.generic');
        setErrorMessage(newErrorMessage);
      } catch (error) {
        setErrorMessage(t('tickets.errors.generic'));
      }
    }
    setTimeout(() => setDisabledBuy(false), 2000);
  };
  const handleHome = (e) => {
    setEmail('');
    navigate('/');
  };

  useEffect(() => {
    if (cart?.length === 0) {
      navigate('/');
    } else {
      const initialize = async () => {
        let outras_areas = '';
        const data = {
          products: cart.map((product) => {
            outras_areas = product.tag === 'outras_areas' ? product.tag : '';
            return { id: product.id, count: product.count };
          }),
          code: 'NOCODE',
        };
        const response = await calculate(data);
        if (response.success) {
          setAmount(response.data.credit_card['1']);
          setCalc(response.data);
        }

        let newCount = 0;
        const newAttendees = Array.from({ length: 1 }, () => {
          return { ...attendeeDefault, email, tag: outras_areas };
        });

        setAttendees(newAttendees);
      };

      initialize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paid]);

  const form = (
    <>
      <div className=''>
        <Header />
        <div className='flex fle-col items-center justify-center mt-6'>
          <Card className='p-6 mb-10'>
            <Row>
              <Col
                md={6}
                className='mb-3'
              >
                <div className='ms-6 mt-6'>
                  <Event t={t} />
                </div>
              </Col>
              <Col
                md={6}
                className='mb-2'
              >
                <div className='mt-6 '>
                  <Cart
                    cart={cart}
                    amount={amount}
                    t={t}
                  />
                  {step === attendees.length + 1 && step > 1 && (
                    <>
                      <PromoCode
                        promoCode={promoCode}
                        setPromoCode={setPromoCode}
                        handlePromoCode={handlePromoCode}
                        t={t}
                      />
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <CardBody>
              {step > 0 && step <= attendees.length && (
                <>
                  <Attendee
                    index={step - 1}
                    attendee={attendees[step - 1]}
                    fillAttendee={fillAttendee}
                    length={attendees?.length}
                    t={t}
                    email={email}
                    isValidDocument={isValidDocument}
                    isValidEmail={isValidEmail}
                  />
                  <Steps
                    textPrev={<i className='bi bi-arrow-left'></i>}
                    handlePrev={handlePrev}
                    textNext={<i className='bi bi-arrow-right'></i>}
                    handleNext={handleNext}
                  />
                </>
              )}
              {step === attendees.length + 1 && step > 1 && (
                <>
                  {transaction.paymentType !== FREE && (
                    <PaymentOption
                      transaction={transaction}
                      handleCreditCard={handleCreditCard}
                      handlePix={handlePix}
                      t={t}
                    />
                  )}

                  {transaction.paymentType === CREDITCARD && (
                    <>
                      <Token
                        cctoken={cctoken}
                        amount={amount}
                        fillToken={fillToken}
                        installments={cart[0].installments}
                        cart={cart}
                        t={t}
                        calc={calc}
                        setAmount={setAmount}
                      />
                    </>
                  )}

                  <Customer
                    customer={customer}
                    fillCustomer={fillCustomer}
                    paymentType={transaction.paymentType}
                    t={t}
                  />
                  <Row>
                    <span className='text-danger text-center w-100'>
                      {errorMessage}
                    </span>
                  </Row>
                  {transaction.paymentType === PIX &&
                    transactionResponse?.data?.pix && (
                      <PixQrCode
                        qrCode={transactionResponse.data?.pix?.qrcode}
                        qrCodeText={transactionResponse?.data?.pix?.qrcode_text}
                        transactionId={transactionResponse?.data?.transactionId}
                        setPaid={setPaid}
                        remainingTime={remainingTime}
                        t={t}
                      />
                    )}

                  <Steps
                    textPrev={<i className='bi bi-arrow-left'></i>}
                    handlePrev={handlePrev}
                    textNext={
                      disabledBuy ? (
                        <>{t('tickets.fields.processing')}</>
                      ) : (
                        <>
                          <i className='bi bi-cart me-2'></i>
                          {t('tickets.fields.tobuy')}
                        </>
                      )
                    }
                    handleNext={handleBuy}
                    disableNext={disabledBuy}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );

  const successMessage = (
    <>
      <div className=''>
        <Header />
        <Success
          handleAction={handleHome}
          paymentType={transaction.paymentType}
          t={t}
        />
      </div>
    </>
  );
  return (
    <>
      <Alert
        isOpen={isOpenModal}
        toggle={toggleModal}
        message={message}
        t={t}
      />
      {paid ? successMessage : form}
    </>
  );
}
