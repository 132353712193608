export default function Event({ t }) {
  return (
    <>
      <h2 className='mb-3 text-3xl font-extrabold lg:leading-[50px] '>
        {t('event.name')}
      </h2>
      <p className='mb-0'>{t('event.date')}</p>
      <p className='mb-0'>{t('event.time')}</p>
      <p className='mb-0'>{t('event.address')}</p>
      <a
        href={t('event.location.link')}
        target={'_blank'}
        rel='noreferrer'
      >
        {t('event.location.text')}
      </a>
    </>
  );
}
