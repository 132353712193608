import './styled.css';
import Banner from '../../assets/images/banner.webp';
import Banner1920 from '../../assets/images/banner1920.webp';

export default function Header() {
  return (
    <div class='grid sm:grid-cols-1 lg:grid-cols-1 w-full'>
      <div>
        <img
          src={Banner}
          className='w-full max-h-[300px] object-contain'
          alt='img'
        />
      </div>
    </div>
  );
  // return (
  //   <div className='d-flex justify-content-center mb-5'>
  //     <img
  //       alt='Banner do evento'
  //       src={Banner}
  //       className='header-img'
  //     />
  //   </div>
  // );

  //   <div class='relative font-sans before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10'>
  //     <img
  //       src='https://readymadeui.com/cardImg.webp'
  //       alt='Banner Image'
  //       class='absolute inset-0 w-full h-full object-cover'
  //     />
  //     <div class='min-h-[350px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6'>
  //       <h2 class='sm:text-4xl text-2xl font-bold mb-6'>Explore the World</h2>
  //       <p class='sm:text-lg text-base text-center text-gray-200'>
  //         Embark on unforgettable journeys. Book your dream vacation today!
  //       </p>
  //       <button
  //         type='button'
  //         class='mt-12 bg-transparent text-white text-base py-3 px-6 border border-white rounded-lg hover:bg-white hover:text-black transition duration-300'
  //       >
  //         Book Now
  //       </button>
  //     </div>
  //   </div>
  // );
}
